<template>
	<span>
		<span @click="onShowModal">
			<slot>
				<a-button :disabled="disabled" type="primary">批量配置</a-button>
			</slot>
		</span>
		<a-modal v-model:visible="visible" title="批量配置使用政策" width="850px" @cancel="onCancel" @ok="onOK">
			<a-spin :spinning="loading">
				<a-form ref="form" :model="formState" name="form" @finish="onSearch">
					<a-row>
						<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
							<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 250px;" @change="(id) => getAllCinemaList(id)">
								<a-select-option :value="0">全部</a-select-option>
								<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
							</a-select>
						</a-form-item>
						
						<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
							<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 250px;">
								<a-select-option :value="0">全部</a-select-option>
								<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
							</a-select>
						</a-form-item>
					</a-row>
					
					<a-row v-show="showAll">
						<a-form-item label="批次号" name="batchNo" class="ui-form__item">
							<a-input v-model:value="formState.batchNo" placeholder="请输入批次号"></a-input>
						</a-form-item>
						
						<a-form-item label="批次号名称" name="batchName" class="ui-form__item">
							<a-input v-model:value="formState.batchName" placeholder="请输入批次号名称"></a-input>
						</a-form-item>
						
						<a-form-item label="是否通兑" name="isHoliday" class="ui-form__item">
							<a-select v-model:value="formState.isHoliday" placeholder="请选择是否通兑" style="width: 160px;">
								<a-select-option :value="1">通兑</a-select-option>
								<a-select-option :value="0">非通兑</a-select-option>
							</a-select>
						</a-form-item>
						
						<a-form-item label="有效期开始时间" class="ui-form__item">
							<a-range-picker v-model:value="time"></a-range-picker>
						</a-form-item>
						
						<a-form-item label="有效期结束时间" class="ui-form__item">
							<a-range-picker v-model:value="time1"></a-range-picker>
						</a-form-item>
					</a-row>
					
					<a-row>
						<a-col :span="18">
							<a style="margin-left: 20px;" @click="showAll = !showAll">
								{{ showAll ? '收起' :'展开' }}
								<Icon v-show="!showAll" icon="DownOutlined"></Icon>
								<Icon v-show="showAll" icon="UpOutlined"></Icon>
							</a>
						</a-col>
						<a-col :span="6" style="text-align: right;">
							<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
							<a-button @click="reset">重置</a-button>
						</a-col>
					</a-row>
				</a-form>			
				
				<a-alert style="margin-top: 10px;" type="info" :message="'当前已选择' + selectedRowKeys.length  + '条'" />
				
				<div style="margin-top: 20px;">
					<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :rowSelection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll, }" :scroll="{ x: 1600 }">
						<template #bodyCell="{ column, record }">
							<template v-if="column.key === 'createdTime'">
								{{ transDateTime(record.createTime) }}
							</template>
							
							<template v-if="column.key === 'time'">
								{{ transDateTime(record.receiveStartTime, 1) }} - {{ transDateTime(record.receiveEndTime, 1) }}
							</template>
							
							<template v-if="column.key === 'redeemedCount'">
								{{ record.importCount - record.redeemedCount }}
							</template>
							
							<template v-if="column.key === 'isHoliday'">
								<div v-if="record.isHoliday">通兑</div>
								<div v-else>非通兑</div>
							</template>
						</template>
					</a-table>
				</div>
			</a-spin>
		</a-modal>
	</span>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getImportRecord } from '@/service/modules/coupon.js';
	import { batchReplaceCouponPolicy } from '@/service/modules/rules.js';
	export default {
		components: {Icon },
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: Array,
				default: () => {
					return []
				}
			},
			disabled: {
				type: Boolean,
				default: false
			},
			policyId: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				visible: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					organizationId: 0,
					cinemaId: 0,
				},
				time: [],
				time1: [],
				cinemaList:[],
				searchData: {},
				showModal: false,
				modelRef: {
					time: []
				},
				list: [],
				columns: [
        //     {
				// 	title: '所属影院',
				// 	dataIndex: 'cinemaName'
				// },
          {
					title: '批次号',
					dataIndex: 'batchNo'
				}, {
					title: '批次号名称',
					dataIndex: 'name'
				}, {
					title: '有效期',
					key: 'time'
				}, {
					title: '是否通兑',
					key: 'isHoliday',
					width: 150
				}, {
					title: '补差金额（元）',
					dataIndex: 'diffPrice',
					width: 150
				}, {
					title: '兑换券数量',
					dataIndex: 'importCount',
					width: 150
				}, {
					title: '已绑定数量',
					dataIndex: 'bindCount',
					width: 150
				}, {
					title: '未兑换数量',
					key: 'redeemedCount',
					width: 130
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				selectedRowKeys: [],
				selectedListData: [],
			}
		},
		created() {
			//this.onSearch();
		},
		methods: {
			onShowModal() {
				if (this.disabled) return;
				this.getOrganizationList();
				this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
				this.pagination.pageSize = 10;
				this.visible = true;
				this.$nextTick(()=> {
					this.reset();
				})
			},
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				if (this.time && this.time.length) {
					this.searchData.validStartTime = this.time[0].startOf('day').unix();
					this.searchData.validEndTime = this.time[1].endOf('day').unix();
				}
				if (this.time1 && this.time1.length) {
					this.searchData.failureStartTime = this.time1[0].startOf('day').unix();
					this.searchData.failureEndTime = this.time1[1].endOf('day').unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.time = [];
				this.time1 = [];
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getImportRecord({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						couponType: 1,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onSelectChange(record, selected) {
				if (selected) {
					if (this.selectedRowKeys.length < 500) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.$message.warn('选择数据不允许大于500条');
					}
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					this.selectedListData = this.selectedListData.filter(item => {
						return item.id !== record.id;
					});
				}
			},
			onSelectAll(selected) {
				if (selected) {
					let showWain = true;
					this.list.forEach(item => {
						if (this.selectedRowKeys.length < 500) {
							if (this.selectedRowKeys.indexOf(item.id) === -1 && item.status !== 2) {
								this.selectedListData.push(JSON.parse(JSON.stringify(item)));
								this.selectedRowKeys.push(item.id);
							}
						} else {
							if (showWain) {
								showWain = false;
								this.$message.warn('选择数据不允许大于500条');
							}
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onCancel() {
			    this.visible = false;
			    this.$emit('cancel');
			    this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
			},
			async onOK() {
				this.loading = true;
				try {
					let ret = await batchReplaceCouponPolicy({
						couponPolicyId: this.policyId,
						ids: this.selectedRowKeys.join(',')
					});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						//this.$emit('update:value', this.selectedRowKeys);
						this.$emit('change', this.selectedListData);
						this.visible = false;
					}
				} catch(e) {
					this.loading = false;
				}
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
