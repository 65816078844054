<template>
	<div>
		<div v-show="!showModal">
			<a-spin :spinning="loading">
				<a-form ref="form" name="form" :model="formState" @finish="onSearch">
					<a-row>
						<a-form-item name="name" label="使用政策名称">
							<a-input v-model:value="formState.name" placeholder="请输入使用政策名称"></a-input>
						</a-form-item>
					</a-row>
					
					<a-row>
						<a-col :span="12">
							<a-button v-permission="['coupon_preferential_rules_add']" type="primary" @click="onAdd">添加使用政策</a-button>
							<span v-permission="['coupon_preferential_rules_batchUse']" style="margin-left: 20px;">
								<recordModal :policyId="selectedRowKeys[0] || 0" :disabled="selectedRowKeys.length === 0" @cancel="onBatchUseCancel" @change="onBatchUseOk"></recordModal>
							</span>
							<!-- <a-button :disabled="selectedRowKeys.length === 0" type="primary" style="margin-left: 20px;">批量配置</a-button> -->
						</a-col>
						<a-col :span="12" style="text-align: right;">
							<a-button type="primary" html-type="submit">查询</a-button>
							<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
						</a-col>
					</a-row>
				</a-form>
				
				<a-table style="margin-top: 10px;" rowKey="id" :dataSource="list" :columns="columns" :pagination="pagination" :rowSelection="{ type: 'radio', selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, }" >
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onSee(record)">
											<a-menu-item>查看规则</a-menu-item>
										</div>
										<div v-permission="['coupon_preferential_rules_edit']" @click="onEdit(record)">
											<a-menu-item>修改</a-menu-item>
										</div>
										<div v-permission="['coupon_preferential_rules_lookUse']" @click="onDetail(record)">
											<a-menu-item>查看使用批次</a-menu-item>
										</div>
										<div v-permission="['coupon_preferential_rules_delete']" @click="onDelete(record)">
											<a-menu-item>删除</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
				
				<a-modal v-model:visible="visible" title="使用政策使用批次" width="700px">
					<template #footer>
						<a-button type="primary" @click="visible = false;">确定</a-button>
					</template>
					<div style="margin-bottom: 10px;">使用政策名称：{{ detail.name || '--' }}</div>
					<a-table rowKey="id" :dataSource="dataList" :columns="dataColumns" :pagination="modalPagination"></a-table>
				</a-modal>
			</a-spin>
		</div>
		<temp v-if="showModal" :id="id" :isEdit="isEdit" :isSee="isSee" @back="onBack"></temp>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import temp from './temp.vue';
	import recordModal from './recordModal.vue';
	import { getCouponPolicyList, deleteCouponPolicy, useBatchNoList } from '@/service/modules/rules.js';
	export default {
		components: { Icon, temp, recordModal },
		data() {
			return {
				loading: false,
				recordIds: [],
				formState: {},
				searchData: {},
				selectedRowKeys: [],
				selectedListData: [],
				list: [],
				columns: [{
					title: '使用政策名称',
					dataIndex: 'name'
				}, {
					title: '规则数量',
					dataIndex: 'num'
				}, {
          title: '创建人',
          dataIndex:'createUserName'
        }, {
					title: '创建时间',
					key: 'time'
				}, {
					title: '操作',
					key: 'action'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				modalPagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.modalPagination.current = page;
						this.getUseBatchNoList();
					},
					onShowSizeChange: (page, pageSize) => {
						this.modalPagination.current = 1;
						this.modalPagination.pageSize = pageSize;
						this.getUseBatchNoList();
					}
				},
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				visible: false,
				detail: {},
				dataList: [],
				dataColumns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				}, {
					title: '批次号',
					dataIndex: 'batchNo'
				}, {
					title: '批次名称',
					dataIndex: 'name'
				}]
			}
		},
		created() {
			this.onSearch();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCouponPolicyList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getUseBatchNoList () {
				this.loading = true;
				try {
					let ret = await useBatchNoList({
						id: this.detail.id,
						page: this.modalPagination.current,
						pageSize: this.modalPagination.pageSize
					})
					this.loading = false;
					if (ret.code === 200) {
						this.modalPagination.total = ret.data.totalCount;
						this.dataList = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onDelete(record) {
				this.$confirm({
					title: '提示',
					content: '确定删除该政策吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deleteCouponPolicy({
								id: record.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功！');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onBatchSet() {
				console.log(this.recordIds)
			},
			onBatchUseCancel() {
				this.selectedRowKeys = [];
				this.selectedListData = [];
			},
			onBatchUseOk() {
				this.onBatchUseCancel();
				this.getData();
			},
			onAdd() {
				this.isEdit = false;
				this.isSee = false;
				this.id = 0;
				this.showModal = true;
			},
			onEdit(record) {
				this.isEdit = true;
				this.id = record.id;
				this.isSee = false;
				this.showModal = true;
			},
			onSee(record) {
				this.isEdit = false;
				this.id = record.id;
				this.isSee = true;
				this.showModal = true;
			},
			onDetail(record) {
				this.detail = record;
				this.visible = true;
				this.modalPagination.current = 1;
				this.modalPagination.pageSize = 10;
				this.dataList = [];
				this.getUseBatchNoList();
			},
			onSelectChange(record, selected) {
				if (selected) {
					this.selectedListData = [JSON.parse(JSON.stringify(record))];
					this.selectedRowKeys = [record.id];
					// this.selectedListData.push(JSON.parse(JSON.stringify(record)));
					// this.selectedRowKeys.push(record.id);
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					this.selectedListData = this.selectedListData.filter(item => {
						return item.id !== record.id;
					});
				}
			},
		}
	}
</script>

<style>
</style>
