<template>
	<div>
		<Header :title="isSee ? '查看使用政策' : isEdit ? '编辑使用政策' : '新增使用政策'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError
				:label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" @finish="onSubmit">
				
				<a-form-item name="name" label="使用政策名称" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input :disabled="isSee" v-model:value="modelRef.name" placeholder="请输入使用政策名称"></a-input>
				</a-form-item>
				
				<a-form-item-rest>
					<policy v-model:value="policyModel" type="preferential" @ok="onPolicyRuleOk"></policy>
					<a-table :columns="columns" :dataSource="modelRef.cardPolicy" :pagination="false" size="small" style="margin-top: 10px;">
						<template #bodyCell="{column, record, index}">
							<template v-if="column.key === 'type'">
								{{ record.versionType === 3 ? '批量定价' : '场次定价' }}
							</template>
							<template v-else-if="column.key === 'isDisabled'">
								<div v-if="record.isDisabled === 0 || record.isDisabled === true">
									启用
								</div>
								<div v-else-if="record.isDisabled === 1 || record.isDisabled === false">
									禁用
								</div>
							</template>
							<template v-if="column.key === 'action'">
								<div style="display: flex;">
									<span>
										<policy :disabled="isSee" :id="modelRef.cinemaId" type="preferential" v-model:value="modelRef.cardPolicy[index]">
											<slot><a-button type="primary" ghost>修改</a-button></slot>
										</policy>
									</span>
									<a-button :disabled="isSee" style="margin-left: 20px;" type="danger" ghost @click="onDelete(index)">删除</a-button>
								</div>
							</template>
						</template>
					</a-table>
				</a-form-item-rest>
				
				<a-form-item style="text-align: center;margin-top: 20px;">
					<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
					<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
				</a-form-item>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import policy from '@/components/policy/index.vue';
	import { saveCouponPolicy, updateCouponPolicy, getCouponPolicyDetail } from '@/service/modules/rules.js';
	export default {
		components: { Header, policy },
		props: {
			isEdit: {
				type: Boolean,
				default: false
			},
			isSee: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				modelRef: {
					cardPolicy: []
				},
				policyModel: {
					versionType: 3,
					isDisabled: true,
					filmType: 1,
					filmList: [],
					hallType: 1,
					hallList: [],
					isHoliday: false,
					visualType: 1,
					dimensionalList: [],
					showList: [],
					startDate: null,
					endDate: null,
					timeList: []
				},
				columns: [{
					title: '政策名称',
					dataIndex: 'pricingName'
				}, {
					title: '优先级',
					dataIndex: 'pricingLevel' 
				}, {
					title: '规则类型',
					key: 'type'
				}, {
					title: '是否启用',
					key: 'isDisabled'
				}, {
					title: '补差金额(元)',
					dataIndex: 'diffPrice'
				}, {
					title: '操作',
					key: 'action'
				}],
				list: [],
			}
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				let hasSameLevel = false;
				let levels = [];
				postData.cinemaPolicies = postData.cardPolicy.map(item => {
					if (levels.indexOf(item.pricingLevel) !== -1) {
						hasSameLevel = true;
					}
					levels.push(item.pricingLevel);
					
					if (item.movieList) {
						//  删掉不需要的字段
						delete item.movieList;
					}
					if (item.cinemaHallList) {
						//  删掉不需要的字段
						delete item.cinemaHallList;
					}
					if (item.hallDataList) {
						delete item.hallDataList;
					}
					if (item.versionType === 4 && item.filmFieldIds) {
						item.filmFieldIds = item.filmFieldIds.join(',');
					}
					item.isDisabled = item.isDisabled ? 0 : 1;
					if (item.versionType === 3) {
						item.isHoliday = item.isHoliday ? 1 : 0;
						item.filmList = item.filmList.join(',');
						item.hallList = item.hallList.join(',');
						item.dimensionalList = item.dimensionalList.join(',');
						if (item.startDate) {
							item.startDate = parseInt(this.moment(item.startDate).startOf('day').valueOf() / 1000);
						} else {
							item.startDate = 0;
						}
						if (item.endDate) {
							item.endDate = parseInt(this.moment(item.endDate).endOf('day').valueOf() / 1000);
						} else {
							item.endDate = 0;
						}
						if(item.timeList) {
							item.timeList.forEach(subItem => {
								subItem.week = subItem.week.join(',');
								subItem.startTime = this.moment(subItem.startTime).get('hour') * 60 *60 + this.moment(subItem.startTime).get('minute') * 60;
								subItem.endTime = this.moment(subItem.endTime).get('hour') * 60 *60 + this.moment(subItem.endTime).get('minute') * 60;
							})
						}
						delete item.showList;
					} else {
						delete item.filmFieldIds;
						delete item.isHoliday;
						delete item.movieList;
						delete item.hallDataList;
						// delete item.filmType;
						item.filmType = 1; // 后端要求选择场次定价是传默认值
						delete item.filmList;
						// delete item.hallType;
						item.hallType = 1; // 后端要求选择场次定价是传默认值
						delete item.hallList;
						delete item.visualType;
						delete item.dimensionalList;
						delete item.startDate;
						delete item.endDate;
						delete item.timeList;
					}
					return item;
				});
				if (hasSameLevel) {
					return this.$message.warn('使用政策中含有相同优先级的政策，无法提交');
				}
				delete postData.cardPolicy;
				try {
					let ret;
					this.loading = true
					if (this.isEdit && this.id) {
						ret = await updateCouponPolicy(postData);
					} else {
						ret = await saveCouponPolicy(postData);
					}
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.onBack(true);
					}
				} catch(e) {
					console.log(e)
					this.loading = false;
				}
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCouponPolicyDetail({
						id: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						if (ret.data.cinemaPolicies && ret.data.cinemaPolicies.length) {
							ret.data.cinemaPolicies.forEach(policyItem => {
								policyItem.isDisabled = policyItem.isDisabled ? false : true;
								policyItem.isHoliday = policyItem.isHoliday ? true : false;
								policyItem.filmList = policyItem.filmList ? policyItem.filmList.split(',') : [];
								policyItem.hallList = policyItem.hallList ? policyItem.hallList.split(',') : [];
								policyItem.showList = policyItem.showList ? JSON.parse(policyItem.showList) : [];
								policyItem.dimensionalList = policyItem.dimensionalList ? policyItem.dimensionalList.split(',').map(item => Number(item)) : [];
								policyItem.startDate = policyItem.startDate ? this.moment(policyItem.startDate *1000) : undefined;
								policyItem.endDate = policyItem.endDate ? this.moment(policyItem.endDate *1000) : undefined;
								
								policyItem.movieList = policyItem.cinemaFilmList ? policyItem.cinemaFilmList.map(item => {
									item.id = item.filmId.toString();
									item.code = item.filmCode;
									item.shortName = item.film;
									return item;
								}) : [];
								delete policyItem.cinemaFilmList;
								
								policyItem.hallDataList = policyItem.cinemaHallList ? policyItem.cinemaHallList.map(item => {
									item.id = item.hallId.toString();
									item.name = item.hall;
									item.code = item.hallCode;
									return item;
								}) : [];
								delete policyItem.cinemaHallList;
								
								if (policyItem.timeList) {
									policyItem.timeList = JSON.parse(policyItem.timeList);
									policyItem.timeList.forEach(item => {
										let startTime = this.moment.duration(Number(item.startTime), 'second').hours() + ':' + this.moment.duration(Number(item.startTime), 'second').minutes();
										let endTime = this.moment.duration(Number(item.endTime), 'second').hours() + ':' + this.moment.duration(Number(item.endTime), 'second').minutes();
										item.week = item.week.split(',').map(subItem => Number(subItem));
										item.startTime =  this.moment(startTime, 'HH:mm');
										item.endTime = this.moment(endTime, 'HH:mm');
									})
								}
							})
							// ret.data.cardPolicy = ret.data.cardPolicy;
						} else {
							ret.data.cinemaPolicies = [];
						}
						ret.data.cardPolicy = ret.data.cinemaPolicies;
						delete ret.data.cinemaPolicies;
						this.modelRef = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onPolicyRuleOk(data) {
				this.modelRef.cardPolicy.push(this.$deepClone(data));
				this.policyModel = {
					versionType: 3,
					isDisabled: true,
					filmType: 1,
					filmList: [],
					hallType: 1,
					hallList: [],
					isHoliday: false,
					visualType: 1,
					dimensionalList: [],
					showList: [],
					startDate: null,
					endDate: null,
					timeList: []
				};
			},
			onDelete(index) {
				this.$confirm({
					title: '提示',
					content: '确定移除吗？',
					onOk: () => {
						this.modelRef.cardPolicy.splice(index, 1);
					}
				})
			}
		}
	}
</script>

<style>
</style>
